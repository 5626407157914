/* customModalStyles.css */
.ant-modal-content {
  background-color: black !important;
  font-family:
  monospace;
  color: white !important;
  border-radius: 8px; /* Optional: Add some rounding to the corners */
  box-shadow: 0 0 100px 10px rgba(255, 242, 0, 0.2) !important; /* Whitish border glow */
}

.ant-modal-header {
  background-color: black !important;
  border-bottom: none !important;
  color: white !important;
}

.ant-modal-title {
  color: white !important;
}

.ant-modal-footer {
  background-color: black !important;
  border-top: none !important;
}

.ant-btn {
  border: 1px solid white !important;
  font-family:
  monospace;
}

.ant-btn-primary {
  background-color: blue !important; /* Keeps Confirm button blue */
  border-color: blue !important;
  font-family:
  monospace;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: darkblue !important; /* Darken button on hover or focus */
  border-color: darkblue !important;
}

.ant-btn-default {
  background-color: black !important;
  color: white !important;
  border-color: white !important;
}

.ant-btn-default:hover,
.ant-btn-default:focus {
  background-color: darkgray !important;
  color: white !important;
  border-color: darkgray !important;
}

.custom-input {
  background-color: #000000; /* Darkish background */
  /* border: 1px solid yellow; Yellow frame */
  color: rgb(162, 148, 148); /* White text */
  font-family: monospace;
}

.custom-input-option {
  background-color: #ffffff; /* Darkish background */
  color: white; /* White text */
  font-family: monospace;
}