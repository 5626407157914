body {
  margin: 0;
  background-color: #000000;
  color:beige;
  font-family:
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App-logo {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-left: 10px;
  filter: invert(1);
}
code {
  font-family:
    monospace;
}
;
.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #282c34;
  min-height: 10vh;
  color: white;
}

.header-content {
  display: flex;
  align-items: center;
}
