.custom-context-menu {
    background-color: #100f0f !important;
    color: white !important;
    border: 1px solid #444 !important;
    border-radius: 4px;
  }
  
  .custom-menu-item {
    background-color: #131313 !important;
    color: white !important;
  }
  
  .custom-menu-item:hover {
    background-color: #0e0e0e !important;
    color: white !important;
  }